<template>
    <page-loading-animation v-if="isLoading" :is-loading="isLoading" />

    <!--Page Content-->
    <div v-else>
        <!--Header-->
        <page-title :divider="true" icon="users" info="View and administer user accounts" title="Users" />
        <app-text v-if="isMobile" class="mt-1" color="grey" size="small">Please note: all searches must be exact matches</app-text>

        <!--Action Bar-->
        <div class="d-flex align-center" :class="isMobile ? 'mt-1' : 'mt-4'">
            <!--Search-->
            <app-form-field
                form-type="textInput"
                append-icon="icons8-search"
                class="mr-4"
                :clearable="true"
                label="Name"
                style="width: 100%"
                v-model.trim="searchByName"
            />

            <!--Add Button-->
            <!--<app-btn class="mr-4" icon="add" label="Add"/>-->

            <!--Filter Button - with a numbered badge-->
            <div style="position: relative">
                <app-btn @click.native="toggleFiltersVisibility" class="mr-4" color="appWhite" icon="filter" icon-color="primary" />
                <span v-if="computedNumberOfActiveFilters > 0" class="badge">{{ computedNumberOfActiveFilters }}</span>
            </div>

            <!--More Actions Menu-->
            <more-actions-menu @emitMenuSelection="handleEmittedMenuSelection" :menuList="moreActionsMenuOption" />
        </div>

        <!--Clear Filters-->
        <div v-if="computedNumberOfActiveFilters > 0" class="d-flex justify-end mt-4">
            <app-btn @click.native="clearFilters" icon="close" label="Clear Filters" />
        </div>

        <!--Table (Desktop Only)-->
        <v-data-table v-if="$vuetify.breakpoint.width >= 600" class="appWhite rounded-lg mt-4" :headers="computedHeaders" :items="computedTableData">
            <!--ID-->
            <template v-slot:item.entityId="{ item }">
                <app-text size="small">{{ item.entityId }}</app-text>
            </template>

            <!--Status-->
            <template v-slot:item.userStatus="{ item }">
                <div class="d-flex justify-space-between">
                    <app-icon
                        v-if="item.userData.userStatus === 'Pending'"
                        color="orange"
                        icon="pending"
                        size="24"
                        :title="item.userData.userStatus"
                    />
                    <app-icon
                        v-if="item.userData.userStatus === 'Approved'"
                        color="green"
                        icon="success"
                        size="24"
                        :title="item.userData.userStatus"
                    />
                    <app-icon v-if="item.userData.userStatus === 'Rejected'" color="red" icon="onHold" size="24" :title="item.userData.userStatus" />
                    <app-icon v-if="item.userData.userStatus === 'Suspended'" color="red" icon="onHold" size="24" :title="item.userData.userStatus" />
                    <app-icon
                        v-if="item.userData.userStatus === 'Deleted'"
                        color="red"
                        icon="userDeleted"
                        size="24"
                        :title="item.userData.userStatus"
                    />

                    <div class="mr-2" />

                    <app-icon
                        v-if="item.userData.userSwappStatus === 'Out'"
                        color="grey"
                        icon="qrCode"
                        size="24"
                        :title="`SWAPPed ${item.userData.userSwappStatus}`"
                    />
                    <app-icon
                        v-if="item.userData.userSwappStatus === 'In'"
                        color="green"
                        icon="qrCode"
                        size="24"
                        :title="`SWAPPed ${item.userData.userSwappStatus}`"
                    />
                </div>
            </template>

            <!--Name-->
            <template v-slot:item.userName="{ item }">
                <app-text size="small">{{ item.userData.userName }}</app-text>
                <app-text color="grey9" size="small">{{ item.userData.userInternalIdNumber }}</app-text>
            </template>

            <!--Type-->
            <template v-slot:item.userLevel="{ item }">
                <app-text size="small">{{ item.userData.userLevel }}</app-text>
            </template>

            <!--Position-->
            <template v-slot:item.userPosition="{ item }">
                <app-text v-if="item.userData.userPosition" size="small">{{ item.userData.userPosition }}</app-text>
                <app-text v-else color="greyD" size="small">N/A</app-text>
            </template>

            <!--Action Button-->
            <template v-slot:item.action="{ item }">
                <div class="d-flex justify-end">
                    <!--<app-icon v-if="checkForMissingProfileData(item.userData)" class="flash" color="red" icon="error" title="Missing data"/>-->
                    <app-icon @click.native="handleOpenRightPanel(item)" class="cursorPointer" color="primary" icon="arrowForward" />
                </div>
            </template>
        </v-data-table>

        <!--Mobile Cards with Pagination-->
        <div v-if="$vuetify.breakpoint.width < 600">
            <!--No data message-->
            <app-text v-if="!mobileUsersData.length" class="text-center mt-4" color="grey9">You have no Users to view</app-text>

            <users-mobile-card
                v-for="item in mobileUsersData"
                :key="item.userData.entityId"
                @click.native="handleOpenRightPanel(item)"
                :user="item"
            />

            <!-- Load More Button (replaces pagination) - Only shown for users, not visitors -->
            <div v-if="hasMoreData && filterByUserCategory === 'users'" class="d-flex justify-center mt-4 mb-4">
                <app-btn @click.native="loadMoreData" :loading="isLoadingMore" icon="expand_more" label="Load More" />
            </div>
        </div>

        <!--Filters Panel ------------------------------------------------------------------------------------------ -->
        <filter-panel :is-filters-panel-visible="isFiltersPanelVisible" @toggle="toggleFiltersVisibility">
            <div class="pa-4">
                <!--User Type Toggle-->
                <page-break-title title="User Type" />
                <div class="mt-4">
                    <v-btn-toggle v-model="filterByUserCategory" mandatory class="d-flex" style="width: 100%">
                        <v-btn value="users" class="flex-grow-1" :outlined="filterByUserCategory !== 'users'">
                            <app-text size="small">Users</app-text>
                        </v-btn>
                        <v-btn value="visitors" class="flex-grow-1" :outlined="filterByUserCategory !== 'visitors'">
                            <app-text size="small">Visitors</app-text>
                        </v-btn>
                    </v-btn-toggle>
                </div>

                <!--Statuses-->
                <page-break-title class="mt-8" title="Statuses" />
                <div class="mt-4" style="display: grid; grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); grid-gap: 16px">
                    <app-btn
                        @click.native="handleFilterStatusSelection('Pending')"
                        :block="true"
                        color="orange"
                        label="Pending"
                        :outlined="!filterByStatuses.includes('Pending')"
                    />
                    <app-btn
                        @click.native="handleFilterStatusSelection('Approved')"
                        :block="true"
                        color="green"
                        label="Approved"
                        :outlined="!filterByStatuses.includes('Approved')"
                    />
                    <app-btn
                        @click.native="handleFilterStatusSelection('Rejected')"
                        :block="true"
                        color="red"
                        label="Rejected"
                        :outlined="!filterByStatuses.includes('Rejected')"
                    />
                    <app-btn
                        @click.native="handleFilterStatusSelection('Suspended')"
                        :block="true"
                        color="red"
                        label="Suspended"
                        :outlined="!filterByStatuses.includes('Suspended')"
                    />
                </div>

                <!--Position-->
                <page-break-title class="mt-8" title="Position" />
                <div class="mt-4">
                    <app-form-field form-type="textInput" label="Position" v-model="filterByPosition" />
                </div>

                <!--Types - Conditional based on User Category-->
                <page-break-title class="mt-8" title="Types" />
                <div class="mt-4">
                    <app-form-field
                        form-type="autoComplete"
                        :deletable-chips="true"
                        :disabled="filterByUserCategory === 'visitors'"
                        :items="filterByUserCategory === 'users' ? ['Contractor', 'Staff'] : []"
                        label="Types"
                        :multiple="true"
                        :small-chips="true"
                        v-model="filterByTypes"
                    />
                </div>

                <!--Roles-->
                <page-break-title class="mt-8" title="Roles" />
                <div class="mt-4">
                    <app-form-field
                        form-type="autoComplete"
                        :deletable-chips="true"
                        :items="['Admin', 'Manager', 'User']"
                        label="Roles"
                        :multiple="true"
                        :small-chips="true"
                        v-model="filterByRoles"
                    />
                </div>

                <!--SWAPP Status-->
                <page-break-title class="mt-8" title="SWAPP Status" />
                <div class="mt-4">
                    <app-form-field
                        form-type="autoComplete"
                        :clearable="true"
                        :items="['In', 'Out']"
                        label="SWAPP Status"
                        v-model="filterBySwappStatus"
                    />
                </div>
            </div>
        </filter-panel>

        <!--Right Side ----------------------------------------------------------------------------------- Right Side-->
        <transition
            enter-active-class="animate__animated animate__fadeInRight animate__faster"
            leave-active-class="animate__animated animate__fadeOutRight animate__faster"
            mode="out-in"
        >
            <div
                v-if="isRightPanelVisible"
                class="appGrey formShadow"
                style="position: absolute; top: 0; bottom: 0; right: 0; overflow: auto"
                :style="$vuetify.breakpoint.width < 600 ? 'width: 100%' : 'width: 50%'"
            >
                <!--Header-->
                <div class="d-flex align-center primary pa-4">
                    <app-text color="appWhite" size="normal">{{ selectedItem.userData.userName }}</app-text>
                    <v-spacer />
                    <app-icon @click.native="closeRightPanel" class="cursorPointer" color="white" icon="close" size="32" />
                </div>

                <!--Action Bar-->
                <div class="d-flex align-center pa-4">
                    <!--User Status-->
                    <div class="d-flex align-center">
                        <app-icon
                            v-if="selectedItem && selectedItem.userData.userStatus === 'Pending'"
                            class="flash mr-4"
                            color="orange"
                            icon="pending"
                            size="48"
                        />
                        <app-icon
                            v-if="selectedItem && selectedItem.userData.userStatus === 'Approved'"
                            class="mr-4"
                            color="green"
                            icon="success"
                            size="48"
                        />
                        <app-icon
                            v-if="selectedItem && selectedItem.userData.userStatus === 'Rejected'"
                            class="mr-4"
                            color="red"
                            icon="onHold"
                            size="48"
                        />
                        <app-icon
                            v-if="selectedItem && selectedItem.userData.userStatus === 'Suspended'"
                            class="mr-4"
                            color="red"
                            icon="onHold"
                            size="48"
                        />
                    </div>

                    <v-spacer />

                    <!--Action Buttons (Overview)-->
                    <edit-icon v-if="tabs === 'overview'" @click.native="editItem" :isActive="!isReadOnly" />
                    <delete-icon
                        v-if="tabs === 'overview' && ['Staff-Admin'].includes(MIX_getCurrentUser().userLevel)"
                        @emitDeleteItem="deleteUser(selectedItem.userData)"
                        itemName="userName"
                        :selectedItem="selectedItem.userData"
                    />

                    <!--Action Buttons (Qualification Form)-->
                    <edit-icon
                        v-if="
                            ['Staff-Admin', 'Staff-Manager'].includes(MIX_getCurrentUser().userLevel) &&
                            tabs === 'qualifications' &&
                            isQualificationFormVisible
                        "
                        @click.native="editItem"
                        :isActive="!isReadOnly"
                    />
                    <delete-icon
                        v-if="
                            ['Staff-Admin', 'Staff-Manager'].includes(MIX_getCurrentUser().userLevel) &&
                            tabs === 'qualifications' &&
                            isQualificationFormVisible
                        "
                        @emitDeleteItem="deleteQualification(selectedQualification?.qualificationData)"
                        itemName="qualificationName"
                        :selectedItem="selectedQualification?.qualificationData"
                    />
                </div>
                <!--Everyone apart from Visitors-->
                <div v-if="selectedItem.userData.userType !== 'Visitor'">
                    <!--Tabs-->
                    <v-tabs v-model="tabs">
                        <v-tab href="#overview">
                            <app-text size="small">Overview</app-text>
                        </v-tab>
                        <v-tab v-if="MIX_isFeatureAllowed('Qualifications')" href="#qualifications">
                            <app-text size="small">Qualifications</app-text>
                        </v-tab>
                        <v-tab v-if="MIX_isFeatureAllowed('Induction')" href="#induction">
                            <app-text size="small">Induction</app-text>
                        </v-tab>
                        <v-tab v-if="MIX_isFeatureAllowed('Orientation')" href="#orientation">
                            <app-text size="small">Orientation</app-text>
                        </v-tab>
                        <v-tab href="#swappHistory">
                            <app-text size="small">SWAPP History</app-text>
                        </v-tab>
                    </v-tabs>

                    <!--Tabs Content-->
                    <v-tabs-items v-model="tabs">
                        <!--Overview-->
                        <v-tab-item value="overview">
                            <user-form @emitReloadPage="emittedReloadPage" class="pa-4" :fullUserData="selectedItem" :isReadOnly="isReadOnly" />
                        </v-tab-item>

                        <!--Qualifications-->
                        <v-tab-item value="qualifications">
                            <user-qualifications-table
                                v-if="!isQualificationFormVisible"
                                @emitHandleAddQualification="handleAddNewQualification"
                                @emitQualificationSelection="openSelectedQualification"
                                @emitReloadData="emittedReloadData"
                                class="appGrey px-4"
                                :full-user-data="selectedItem"
                                :isReadOnly="isReadOnly"
                                parentView="users"
                                :qualification-categories-data="qualificationCategoriesData"
                                :qualification-presets-data="qualificationPresetsData"
                            />

                            <user-qualification-form
                                v-if="isQualificationFormVisible"
                                @closeQualificationForm="toggleQualificationFormVisibility"
                                @emitReloadData="emittedReloadData"
                                class="pa-4"
                                :formData="selectedQualification"
                                :full-user-data="selectedItem"
                                :isReadOnly="isReadOnly"
                                parentView="users"
                                :qualification-categories-data="qualificationCategoriesData"
                                :qualification-presets-data="qualificationPresetsData"
                            />
                        </v-tab-item>

                        <!--Induction-->
                        <v-tab-item class="appGrey pa-4" value="induction">
                            <user-inductions :induction-data="selectedItem?.inductionData" />
                        </v-tab-item>

                        <!--Orientations-->
                        <v-tab-item class="appGrey pa-4" value="orientation">
                            <user-orientations :orientations-data="selectedItem?.orientationsData" />
                        </v-tab-item>

                        <v-tab-item value="swappHistory">
                            <user-swapp-history class="appGrey pa-4" :user-data="selectedItem" />
                        </v-tab-item>
                    </v-tabs-items>
                </div>

                <!--Visitors Only-->
                <div v-if="selectedItem.userData.userType === 'Visitor'">
                    <!--Tabs-->
                    <v-tabs v-model="tabs">
                        <v-tab href="#overview">
                            <app-text size="small">Overview</app-text>
                        </v-tab>
                        <v-tab href="#swappHistory">
                            <app-text size="small">SWAPP History</app-text>
                        </v-tab>
                    </v-tabs>

                    <!--Tabs Content-->
                    <v-tabs-items v-model="tabs">
                        <v-tab-item value="overview">
                            <user-visitor-form
                                @emitReloadPage="emittedReloadPage"
                                class="pa-4"
                                :user-data="selectedItem"
                                :is-read-only="isReadOnly"
                            />
                        </v-tab-item>

                        <v-tab-item value="swappHistory">
                            <user-swapp-history class="appGrey pa-4" :user-data="selectedItem" />
                        </v-tab-item>
                    </v-tabs-items>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import UsersMobileCard from '@/views/users/userMobileCard/UserMobileCard'
import UserForm from '@/views/users/userForm/UserForm'
import UserQualificationsTable from '@/views/users/userQualificationsTable/UserQualificationsTable.vue'
import UserQualificationForm from '@/views/users/userQualificationForm/UserQualificationForm.vue'
import UserInductions from '@/views/users/userInductions/UserInductions.vue'
import UserOrientations from '@/views/users/userOrientations/UserOrientations.vue'
import UserSwappHistory from '@/views/users/userSwappHistory/UserSwappHistory.vue'
import UserVisitorForm from '@/views/users/userVisitorForm/UserVisitorForm.vue'

export default {
    name: 'Users',

    components: {
        UserVisitorForm,
        UserSwappHistory,
        UserOrientations,
        UserInductions,
        UserQualificationForm,
        UserQualificationsTable,
        UserForm,
        UsersMobileCard
    },

    data: () => ({
        filterByUserCategory: 'users', // Default to showing users
        filterByPayGrades: [],
        filterByPosition: '',
        filterByRoles: [],
        filterByStatuses: [],
        filterBySwappStatus: '',
        filterByTypes: [],
        isFiltersPanelVisible: false,
        isLoading: true,
        isQualificationFormVisible: false,
        isReadOnly: false,
        isRightPanelVisible: false,

        // Mobile pagination properties
        mobileCurrentPage: 1,
        mobilePageSize: 10,
        mobileTotalItems: 0,
        mobileUsersData: [], // Separate data store for mobile

        // Mobile load more properties
        hasMoreData: false, // Indicates if there's more data to load
        isLoadingMore: false, // Loading state for the load more button
        mobileAllUsersLoaded: false, // Flag to track if all users are loaded

        moreActionsMenuOption: [{ name: 'Export', icon: 'export' }],
        searchByName: '',
        searchByTeam: '',
        searchByLevel: '',
        selectedItem: {},
        tableHeaders: [
            {
                text: 'ID',
                value: 'id',
                align: 'start',
                sortable: false,
                hidden: true
            },
            {
                text: 'Status',
                value: 'userStatus',
                align: 'center',
                sortable: false,
                width: '10px'
            },
            { text: 'Name', value: 'userName', align: 'start', sortable: false },
            { text: 'Level', value: 'userLevel', align: 'start', sortable: false },
            {
                text: 'Position',
                value: 'userPosition',
                align: 'start',
                sortable: false
            },
            {
                text: '',
                value: 'action',
                align: 'right',
                sortable: false,
                width: '48px'
            }
        ],
        tabs: '',

        // Data
        fullUsersData: [],
        qualificationCategoriesData: [],
        qualificationPresetsData: [],
        selectedQualification: {},
        teamsData: [],
        usersData: [],
        visitorsData: []
    }),

    computed: {
        /**
         * Check if we're on a mobile device
         *
         * @returns {boolean} true if on mobile
         */
        isMobile() {
            return this.$vuetify.breakpoint.width < 600
        },

        /**
         * User Type Options
         *
         * Return the appropriate user type options based on the selected user category
         *
         * @returns {array} Array of user type options
         */
        userTypeOptions() {
            return this.filterByUserCategory === 'users' ? ['Contractor', 'Staff'] : []
        },

        /**
         * Computed Export CSV
         *
         * Return the data and headers for the CSV export
         *
         * @returns {{headers: {}, data: *[]}}
         */
        computedExportCSV() {
            const t = this
            let data = []
            let headers = {}

            // Add the readable headers for the CSV columns
            headers = {
                entityId: 'ID',
                userStatus: 'Status',
                userInternalIdNumber: 'Internal ID',
                userName: 'Name',
                userPosition: 'Position',
                userEmail: 'Email',
                userTelephone: 'Telephone',
                userType: 'Type',
                userRole: 'Role',
                userLevel: 'Level'
            }

            // Get the right data source based on device
            const tableData = this.isMobile ? this.mobileUsersData : this.computedTableData

            // Add the data
            tableData.forEach((entry) => {
                const DATA_OBJECT = {
                    entityId: entry?.userData?.entityId,
                    userStatus: entry?.userData?.userStatus,
                    userInternalIdNumber: entry?.userData?.userInternalIdNumber,
                    userName: entry?.userData?.userName,
                    userPosition: entry?.userData?.userPosition,
                    userEmail: entry?.userData?.userEmail,
                    userTelephone: entry?.userData?.userTelephone,
                    userType: entry?.userData?.userType,
                    userRole: entry?.userData?.userRole,
                    userLevel: entry?.userData?.userLevel
                }

                data.push(DATA_OBJECT)
            })

            return { headers, data }
        },

        /**
         * Computed Number of Active Filters
         *
         * Count the number of active filters to display in the filters button badge.
         *
         * @returns {*}
         */
        computedNumberOfActiveFilters() {
            const t = this
            return [
                t.filterByStatuses.length,
                t.filterByPayGrades.length,
                t.filterByPosition ? 1 : 0,
                t.filterByTypes.length,
                t.filterByRoles.length,
                t.filterBySwappStatus ? 1 : 0
                // We don't count user category filter since it's always on
            ].reduce((acc, curr) => acc + curr, 0)
        },

        /**
         * Computed Headers
         *
         * Remove hidden headers and only show the actions column to those with access.
         *
         * @returns an array of header objects
         */
        computedHeaders() {
            const t = this
            const CURRENT_USER_DATA = t.MIX_getCurrentUser()
            let headers = t.tableHeaders

            // Remove hidden headers
            headers = headers.filter((h) => !h.hidden)

            // Only those with access can view the actions column
            if (!['Staff-Admin'].includes(CURRENT_USER_DATA.userLevel)) {
                headers = headers.filter((h) => h.value !== 'action')
            }

            return headers
        },

        /**
         * Computed Table Data
         *
         * Sort and return the form data for desktop view.
         * For mobile, this isn't used - we use mobileUsersData instead.
         *
         * @returns {array[{}]} an array of objects
         */
        computedTableData() {
            const t = this

            // For mobile, this isn't used, so we can just return an empty array
            if (t.isMobile) {
                return []
            }

            const FULL_USERS_DATA = t.fullUsersData
            const VISITORS_DATA = t.visitorsData
            let tableData = []

            // Filter by User Category
            if (t.filterByUserCategory === 'users') {
                tableData = [...FULL_USERS_DATA]
            } else {
                tableData = [
                    ...VISITORS_DATA.map((visitor) => ({
                        teamData: {},
                        userData: visitor
                    }))
                ]
            }

            // Search by Name
            if (t.searchByName) {
                const SEARCH_CRITERIA = t.searchByName.toUpperCase()
                tableData = tableData.filter((item) => {
                    const USER_NAME = item.userData.userName ? item.userData.userName.toUpperCase() : ''
                    return USER_NAME.includes(SEARCH_CRITERIA)
                })
            }

            // Hide "Suspended" and "Rejected" by default
            if (!t.filterByStatuses.includes('Suspended') && !t.filterByStatuses.includes('Rejected')) {
                tableData = tableData.filter((item) => item.userData.userStatus !== 'Suspended' && item.userData.userStatus !== 'Rejected')
            }

            // Filter by Status
            if (t.filterByStatuses.length) {
                tableData = tableData.filter((item) => t.filterByStatuses.includes(item.userData.userStatus))
            }

            // Filter by Position
            if (t.filterByPosition) {
                const SEARCH_CRITERIA = t.filterByPosition.toUpperCase()
                tableData = tableData.filter((item) => {
                    const USER_POSITION = item.userData.userPosition ? item.userData.userPosition.toUpperCase() : ''
                    return USER_POSITION.includes(SEARCH_CRITERIA)
                })
            }

            // Filter by Pay Grades
            if (t.filterByPayGrades.length) {
                tableData = tableData.filter((item) => t.filterByPayGrades.includes(item.userData.userPayGrade))
            }

            // Filter by Types
            if (t.filterByTypes.length) {
                tableData = tableData.filter((item) => t.filterByTypes.includes(item.userData.userType))
            }

            // Filter by Roles
            if (t.filterByRoles.length) {
                tableData = tableData.filter((item) => t.filterByRoles.includes(item.userData.userRole))
            }

            // Filter by SWAPP Status
            if (t.filterBySwappStatus) {
                tableData = tableData.filter((item) => item.userData.userSwappStatus === t.filterBySwappStatus)
            }

            tableData = tableData.sort((a, b) => (a.userData.userName > b.userData.userName ? 1 : -1))

            return tableData
        }
    },

    watch: {
        /**
         * Watch for device width changes to handle switching between mobile/desktop
         */
        '$vuetify.breakpoint.width': {
            handler(newWidth) {
                if (newWidth < 600) {
                    // Switched to mobile - load paginated data
                    this.loadMobileData()
                } else {
                    // Switched to desktop - load all data if needed
                    this.loadFullUsersData()
                }
            }
        },

        /**
         * Watch for changes to filterByUserCategory
         *
         * When switching categories, reload appropriate data
         */
        filterByUserCategory(newValue) {
            // Clear types filter when switching to visitors
            if (newValue === 'visitors') {
                this.filterByTypes = []
            }

            // Reload data for desktop
            if (!this.isMobile) {
                if (newValue === 'users') {
                    this.loadFullUsersData()
                } else {
                    this.loadVisitorsData()
                }
            } else {
                // For mobile, reload paginated data
                this.mobileCurrentPage = 1
                this.loadMobileData()
            }
        },

        /**
         * Watchers for mobile filters
         * Only trigger data reload when on mobile
         */
        searchByName: {
            handler() {
                if (this.isMobile) {
                    this.mobileCurrentPage = 1 // Reset to first page
                    this.loadMobileData()
                }
            },
            debounce: 300
        },

        filterByStatuses: {
            handler() {
                if (this.isMobile) {
                    this.mobileCurrentPage = 1
                    this.loadMobileData()
                }
            }
        },

        filterByPosition: {
            handler() {
                if (this.isMobile) {
                    this.mobileCurrentPage = 1
                    this.loadMobileData()
                }
            },
            debounce: 300
        },

        filterByPayGrades: {
            handler() {
                if (this.isMobile) {
                    this.mobileCurrentPage = 1
                    this.loadMobileData()
                }
            }
        },

        filterByTypes: {
            handler() {
                if (this.isMobile) {
                    this.mobileCurrentPage = 1
                    this.loadMobileData()
                }
            }
        },

        filterByRoles: {
            handler() {
                if (this.isMobile) {
                    this.mobileCurrentPage = 1
                    this.loadMobileData()
                }
            }
        },

        filterBySwappStatus: {
            handler() {
                if (this.isMobile) {
                    this.mobileCurrentPage = 1
                    this.loadMobileData()
                }
            }
        }
    },

    methods: {
        /**
         * Clear Filters
         *
         * Clear all the filters.
         */
        clearFilters() {
            const t = this

            t.filterByPayGrades = []
            t.filterByPosition = ''
            t.filterByRoles = []
            t.filterByStatuses = []
            t.filterByTypes = []
            t.filterBySwappStatus = ''
            // Don't reset filterByUserCategory as it's a mandatory toggle
        },

        /**
         * Check For Missing Profile Data
         *
         * If the user is missing profile data, return true.
         * Data is calculated by userType.
         *
         * @param item {object} - the user object
         * @returns {boolean} - true if missing data
         */
        checkForMissingProfileData(item) {
            let isMissingData = false

            // Staff
            if (item.userType === 'Staff') {
                if (!item.userPayGrade) isMissingData = true
            }

            // Steward
            if (item.userType === 'Steward') {
                if (!item.userInternalIdNumber) isMissingData = true
                if (!item.userPayGrade) isMissingData = true
                if (!item.userTeam) isMissingData = true
            }

            return isMissingData
        },

        /**
         * Close Right Panel
         *
         * Close the right panel.
         */
        closeRightPanel() {
            const t = this

            t.isQualificationFormVisible = false
            t.isRightPanelVisible = false
            t.selectedItem = {}
            t.selectedQualification = {}
            t.tabs = ''
        },

        /**
         * Delete Qualification
         *
         * Delete the selected Qualification.
         *
         * @param itemData {object} the item to delete
         */
        async deleteQualification(itemData) {
            const t = this

            const RESPONSE = await t.MIX_redis_delete('qualification', itemData.entityId, itemData)

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error deleting Qualification', RESPONSE.error)
                return
            }

            t.$sharedState.successMessage = 'Deleted Qualification'

            // Reload the data
            await t.loadData()

            // Close the form
            t.toggleQualificationFormVisibility()
        },

        /**
         *
         * Delete User
         *
         * Delete the selected document by its ID.
         *
         * @param itemData {object} the item to delete
         */
        async deleteUser(itemData) {
            const t = this

            const RESPONSE = await t.MIX_redis_delete('user', itemData.entityId, itemData)

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error deleting User', RESPONSE.error)
                return
            }

            t.$sharedState.successMessage = 'Deleted User'

            // Reload the data
            await t.loadData()

            // Close the panel
            t.closeRightPanel()
        },

        /**
         * Edit Item
         *
         * Toggle the editability of the form.
         */
        editItem() {
            const t = this

            t.isReadOnly = !t.isReadOnly
        },

        /**
         * Emitted Reload Data
         *
         * Emit the reload data event to the parent component.
         */
        async emittedReloadData() {
            const t = this

            await t.loadFullUserData(t.selectedItem.userData.userFbId)

            // Close the form
            t.isQualificationFormVisible = false
        },

        /**
         * Emitted Reload Page
         *
         * Close the right panel and reload the data to refresh the page.
         */
        emittedReloadPage() {
            const t = this

            t.closeRightPanel()
            t.loadData()
        },

        /**
         * Export Data
         *
         * Export the data to CSV.
         */
        exportData() {
            const t = this

            t.MIX_exportDocuments(t.computedExportCSV.headers, 'Users', t.computedExportCSV.data)
        },

        /**
         * Handle Add New Qualification
         *
         * Handle the Add New Qualification button click.
         */
        handleAddNewQualification() {
            const t = this

            t.selectedQualification = {}
            t.isReadOnly = false
            t.isQualificationFormVisible = true
        },

        /**
         * Handle Emitted Menu Selection
         *
         * Take the emitted selection from the menu, and call the relevant function.
         *
         * @param selection a string-based menu option
         */
        handleEmittedMenuSelection(selection) {
            const t = this

            if (selection === 'Export') t.exportData()
        },

        /**
         * Handle Filter Status Selection
         *
         * Add or remove the selected status from the filterStatuses array.
         *
         * @param status {string} the status to add or remove
         */
        handleFilterStatusSelection(status) {
            const t = this

            if (t.filterByStatuses.includes(status)) {
                t.filterByStatuses = t.filterByStatuses.filter((s) => s !== status)
            } else {
                t.filterByStatuses.push(status)
            }
        },

        /**
         * Handle Open Right Panel
         *
         * Open the right panel and load the full user data.
         *
         * @param item {object} the selected item
         */
        async handleOpenRightPanel(item) {
            const t = this

            t.isLoading = true

            if (item.userData.userType !== 'Visitor') {
                await Promise.all([t.loadFullUserData(item.userData.userFbId)])
            } else {
                t.openRightPanel(item)
            }

            t.isLoading = false
        },

        /**
         * Handle Mobile Pagination
         *
         * Change the current page for mobile view
         *
         * @param {number} page - The page number to load
         */
        handleMobilePagination(page) {
            this.mobileCurrentPage = page
            this.loadMobileData()
        },

        /**
         * Load Data
         *
         * Load all the data required for the page based on device type.
         *
         * @returns {Promise<void>}
         */
        async loadData() {
            const t = this
            t.isLoading = true

            if (t.isMobile) {
                // Load paginated data for mobile
                await Promise.all([t.loadMobileData(), t.loadQualificationPresetsData(), t.loadQualificationCategoriesData()])
            } else {
                // Load all data for desktop
                await Promise.all([
                    t.loadFullUsersData(),
                    t.loadQualificationPresetsData(),
                    t.loadQualificationCategoriesData(),
                    t.loadVisitorsData()
                ])
            }

            t.isLoading = false
        },

        /**
         * Load Mobile Data
         *
         * Load paginated and filtered data for mobile view
         * This method now resets the data and starts from page 1
         *
         * @returns {Promise<void>}
         */
        async loadMobileData() {
            const t = this

            // Skip if not on mobile
            if (!t.isMobile) return

            // Reset pagination and accumulated data
            t.mobileCurrentPage = 1
            t.mobileUsersData = []
            t.mobileAllUsersLoaded = false

            // This is now the initial load, should be called after filter changes

            // Handle visitors category - load all visitors without pagination
            if (t.filterByUserCategory === 'visitors') {
                try {
                    const response = await t.MIX_redis_getEntitiesByWhereAndFields(
                        'Visitor',
                        [
                            { whereKey: 'userStatus', whereValue: 'Approved' },
                            { whereKey: 'isDeleted', whereValue: false }
                        ],
                        [],
                        null, // No pagination for visitors
                        null // No page size for visitors
                    )

                    if (response.hasErrors) {
                        console.error('Error getting Visitor data:', response.error)
                        t.$sharedState.errorMessage = 'There was a problem getting the Visitor data, please try again.'
                        return
                    }

                    // Map visitors to the same format as users
                    let visitorData = response.data.map((visitor) => ({
                        teamData: {},
                        userData: visitor
                    }))

                    // Sort visitors by userName (client-side sort)
                    visitorData.sort((a, b) => {
                        const nameA = a.userData.userName ? a.userData.userName.toUpperCase() : ''
                        const nameB = b.userData.userName ? b.userData.userName.toUpperCase() : ''
                        return nameA.localeCompare(nameB)
                    })

                    t.mobileUsersData = visitorData

                    // For visitors, we load all at once, so there's no more data
                    t.hasMoreData = false
                } catch (error) {
                    console.error('Error loading visitors:', error)
                }
                return
            }

            // For users category - construct the search query
            const searchQuery = []

            searchQuery.push('@isDeleted:{false}')

            if (t.searchByName) {
                searchQuery.push(`@userName:{${t.searchByName}}`)
            }

            if (t.filterByPosition) {
                searchQuery.push(`@userPosition:{${t.filterByPosition}}`)
            }

            if (t.filterByPayGrades && t.filterByPayGrades.length > 0) {
                const payGrades = t.filterByPayGrades.join('|')
                searchQuery.push(`@userPayGrade:{${payGrades}}`)
            }

            if (t.filterByTypes && t.filterByTypes.length > 0) {
                // Exact match for userType
                const escapedTypes = t.filterByTypes.map((type) => type.replace(/[-]/g, '\\$&')).join('|')
                searchQuery.push(`@userType:{${escapedTypes}}`)
            }

            if (t.filterByRoles && t.filterByRoles.length > 0) {
                const roles = t.filterByRoles.join('|')
                searchQuery.push(`@userRole:{${roles}}`)
            }

            if (t.filterBySwappStatus) {
                searchQuery.push(`@userSwappStatus:${t.filterBySwappStatus}`)
            }
            if (t.filterByStatuses && t.filterByStatuses.length > 0) {
                const statuses = t.filterByStatuses.join('|')
                searchQuery.push(`@userStatus:{${statuses}}`)
            } else {
                // Exclude "Suspended" and "Rejected" by default
                searchQuery.push('(-@userStatus:{Suspended|Rejected})')
            }

            // Join all parts of the query with spaces
            const queryString = searchQuery.join(' ')

            try {
                const response = await t.MIX_redis_getFullUsersData(queryString, t.mobileCurrentPage, t.mobilePageSize)

                if (response.hasErrors) {
                    console.error('Error getting mobile Users data:', response.error)
                    return
                }

                t.mobileUsersData = response.data || []
                t.mobileTotalItems = response.total || 0

                // Determine if there's more data to load
                t.hasMoreData = response.data.length >= t.mobilePageSize && (response.total ? t.mobileUsersData.length < response.total : true)
            } catch (error) {
                console.error('Error loading mobile data:', error)
            }
        },

        /**
         * Load More Data
         *
         * Incrementally load more data for mobile view
         * This is only for users, not visitors (which are loaded all at once)
         */
        async loadMoreData() {
            const t = this
            t.isLoadingMore = true

            // Only proceed if we're looking at users
            if (t.filterByUserCategory !== 'users') {
                t.isLoadingMore = false
                return
            }

            // Increment the page
            t.mobileCurrentPage++

            // For users - construct query logic
            const searchQuery = []

            searchQuery.push('@isDeleted:{false}')

            if (t.searchByName) {
                searchQuery.push(`@userName:${t.searchByName}*`)
            }

            if (t.filterByStatuses && t.filterByStatuses.length > 0) {
                const statuses = t.filterByStatuses.join('|')
                searchQuery.push(`@userStatus:{${statuses}}`)
            } else {
                // Exclude "Suspended" and "Rejected" by default
                searchQuery.push('-@userStatus:{Suspended|Rejected}')
            }

            if (t.filterByPosition) {
                searchQuery.push(`@userPosition:${t.filterByPosition}*`)
            }

            if (t.filterByPayGrades && t.filterByPayGrades.length > 0) {
                const payGrades = t.filterByPayGrades.join('|')
                searchQuery.push(`@userPayGrade:{${payGrades}}`)
            }

            if (t.filterByTypes && t.filterByTypes.length > 0) {
                const types = t.filterByTypes.join('|')
                searchQuery.push(`@userType:{${types}}`)
            }

            if (t.filterByRoles && t.filterByRoles.length > 0) {
                const roles = t.filterByRoles.join('|')
                searchQuery.push(`@userRole:{${roles}}`)
            }

            if (t.filterBySwappStatus) {
                searchQuery.push(`@userSwappStatus:${t.filterBySwappStatus}`)
            }

            // Join all parts of the query with spaces
            const queryString = searchQuery.join(' ')

            try {
                const response = await t.MIX_redis_getFullUsersData(queryString, t.mobileCurrentPage, t.mobilePageSize)

                if (response.hasErrors) {
                    console.error('Error getting mobile Users data:', response.error)
                    t.isLoadingMore = false
                    return
                }

                const newData = response.data || []

                // Check if we've loaded all data
                if (newData.length < t.mobilePageSize) {
                    t.hasMoreData = false
                    t.mobileAllUsersLoaded = true
                } else {
                    t.hasMoreData = true
                }

                // Append new data to existing data
                t.mobileUsersData = [...t.mobileUsersData, ...newData]
            } catch (error) {
                console.error('Error loading more user data:', error)
            }

            t.isLoadingMore = false
        },

        /**
         * Load Full User Data
         *
         * Load User data from the database.
         *
         * @param userFbId {string} the user's Firebase ID
         * @returns {Promise<void>}
         */
        async loadFullUserData(userFbId) {
            const t = this

            const RESPONSE = await t.MIX_redis_getFullUserData(userFbId)

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error getting full User data: ', RESPONSE.error)
                return
            }

            // Assign the data to the state
            t.fullUserData = RESPONSE.data

            t.openRightPanel(RESPONSE.data)
        },

        /**
         * Load Full Users Data
         *
         * Load all User data from the database for desktop view.
         *
         * @returns {Promise<void>}
         */
        async loadFullUsersData() {
            const t = this
            // add isDeleted: false to the query

            const queryString = '@isDeleted:{false}'

            // For desktop, load all data without pagination
            const RESPONSE = await t.MIX_redis_getFullUsersData(queryString)

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error getting full Users data: ', RESPONSE.error)
                return
            }

            // Assign the data to the state
            t.fullUsersData = RESPONSE.data
        },

        /**
         * Load Qualification Categories Data
         *
         * Load the required data from the database.
         *
         * @returns {Promise<void>}
         */
        async loadQualificationCategoriesData() {
            const t = this

            // Fetch the data
            const RESPONSE = await t.MIX_redis_getAll('qualificationCategory')

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error getting Qualfication data: ', RESPONSE.error)
                t.$sharedState.errorMessage = 'There was a problem getting the Qualification data, please try again.'
                return
            }
            // Assign the data
            t.qualificationCategoriesData = RESPONSE.data
        },

        /**
         * Load Qualification Presets Data
         *
         * Load the required data from the database.
         *
         * @returns {Promise<void>}
         */
        async loadQualificationPresetsData() {
            const t = this

            // Fetch the data
            const RESPONSE = await t.MIX_redis_getAll('qualificationPreset')

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error getting Qualfication data: ', RESPONSE.error)
                t.$sharedState.errorMessage = 'There was a problem getting the Qualification data, please try again.'
                return
            }

            // Assign the data
            t.qualificationPresetsData = RESPONSE.data
        },

        /**
         * Load Visitors Data
         *
         * Load the required data from the database.
         *
         * @returns {Promise<void>}
         */
        async loadVisitorsData() {
            const t = this

            // Fetch the data - no pagination for desktop
            const RESPONSE = await t.MIX_redis_getEntitiesByWhereAndFields('Visitor', [{ whereKey: 'userStatus', whereValue: 'Approved' }], [])

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error getting Visitor data: ', RESPONSE.error)
                t.$sharedState.errorMessage = 'There was a problem getting the Visitor data, please try again.'
                return
            }

            // Sort visitors by userName
            const sortedVisitors = RESPONSE.data.sort((a, b) => {
                const nameA = a.userName ? a.userName.toUpperCase() : ''
                const nameB = b.userName ? b.userName.toUpperCase() : ''
                return nameA.localeCompare(nameB)
            })

            // Assign the sorted data
            t.visitorsData = sortedVisitors
        },

        /**
         * Open Form
         *
         * Open the form dialog.
         * If no item is passed in, the form will open as new (blank).
         *
         * @param item the selected key
         */
        openRightPanel(item) {
            const t = this

            // If an item is passed in, it's editable
            if (item.userData?.entityId) {
                t.isReadOnly = true
                t.selectedItem = item
            }
            // Otherwise, it's new
            else {
                t.isReadOnly = false
                t.selectedItem = {}
            }

            t.isRightPanelVisible = true
        },

        /**
         * Open Selected Qualification
         *
         * Open the selected Qualification.
         *
         * @param item {object} the selected item
         */
        openSelectedQualification(item) {
            const t = this

            //Set the selected qualification
            t.selectedQualification = item

            t.isQualificationFormVisible = true
        },

        /**
         * Toggle Qualification Form Visibility
         *
         * Toggle the Qualification form visibility between true and false.
         */
        toggleQualificationFormVisibility() {
            const t = this

            t.isQualificationFormVisible = !t.isQualificationFormVisible
        },

        /**
         * Toggle Filters Visibility
         *
         * Toggle the visibility of the filters panel.
         */
        toggleFiltersVisibility() {
            const t = this

            t.isFiltersPanelVisible = !t.isFiltersPanelVisible
        }
    },

    async mounted() {
        const t = this

        // check if filters in localStorage
        const filters = t.MIX_getFromLocalStorage('usersFilters')

        if (filters) {
            t.filterByStatuses = filters.filterByStatuses
        }
        // clear localStorage
        t.MIX_deleteFromLocalStorage('usersFilters')

        await t.loadData()
    }
}
</script>
